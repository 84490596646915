import { FormStrategy } from './form-strategy'
import { RegistrationFormStrategy } from './registration-form-strategy'

interface FormStrategyConstructor {
  new ($w, linksUtil, ravenInstance): FormStrategy

  isEnabled($w): boolean
}

export const strategies: FormStrategyConstructor[] = [RegistrationFormStrategy, FormStrategy]
